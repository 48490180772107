<script setup lang="ts">
const { data: page } = await useAsyncData(() => useDirectus(readSingleton("Homepage", { fields: ["*", { banner_image: ["*"] }] })));
</script>
<template>
  <div>
    <CommonPageBanner
      :local-asset="page?.banner_image ? false : true"
      :image="page?.banner_image ? page.banner_image.id : '/home-banner.png'"
      size="large"
    >
      <div class="mx-auto max-w-[1440px]">
        <p class="font-script text-xl md:text-2xl xl:text-3xl">Join Our Community</p>
        <h1 class="mx-auto text-4xl font-semibold md:text-6xl xl:text-banner">
          {{ page?.title ? page.title : "Care for Tasmania's wild places, wildlife & cultural heritage" }}
        </h1>
      </div>
    </CommonPageBanner>
    <HomeGridBoxes />
    <HomeBranchSection />
    <HomeEventSection />
    <HomeNewsSection />
  </div>
</template>
