<script setup lang="ts">
const { data: news, pending } = await useAsyncData(() =>
  useDirectus(
    readItems("news", {
      fields: ["*", { thumbail: ["*"], branch: ["title"] }],
      filter: {
        status: { _eq: "published" },
      },
      limit: 10,
      sort: "-date_created",
    })
  )
);
</script>

<template>
  <div class="relative bg-tertiary">
    <img class="absolute right-[10%] top-0 z-10" src="/devil-footprints-light-1.svg" alt="" aria-hidden="true" />
    <img class="absolute bottom-0 left-0 z-10" src="/devil-footprints-light-2.svg" alt="" aria-hidden="true" />
    <CommonContainer tag="section" class="relative z-20 py-[--y-margin]">
      <div class="mb-16 flex flex-wrap items-end justify-between gap-5 text-white">
        <div>
          <p class="text-center font-script text-3xl font-bold text-primary lg:text-left">Latest News</p>
          <h2 class="max-w-[775px] text-center text-5xl font-semibold text-accentDark lg:text-left">Read about the latest happenings</h2>
        </div>
        <CommonButtonNuxtLink class="mx-auto md:mx-px" btn-style="primary" :to="{ path: '/news' }"> More news </CommonButtonNuxtLink>
      </div>
      <font-awesome-icon v-if="pending" class="text-primary" icon="fa-solid fa-spinner" size="2xl" spin-pulse></font-awesome-icon>
      <NewsSlider v-else :news="news"></NewsSlider>
    </CommonContainer>
  </div>
</template>
