<script setup lang="ts">
const tiles = [
  {
    heading: "Join Wildcare",
    copy: "We invite you to become a member of Wildcare Tasmania. Just by joining, you are already supporting our volunteers and making a positive impact.",
    buttonText: "Become a Wildcare Member",
    buttonLink: "/join",
  },
  {
    heading: "About Us",
    copy: "Wildcare Tasmania is a community of volunteers and donors caring for Tasmania's wild places, wildlife and cultural heritage. Wildcare has almost 60 volunteer groups that members can join.",
    buttonText: "About Us",
    buttonLink: "/about",
  },
  {
    heading: "Get Involved",
    copy: "We offer inspiring opportunities in beautiful places, working in close partnership with the Tasmanian Government, councils, and private landowners.",
    buttonText: "Join a Group",
    buttonLink: "/groups",
  },
];
</script>

<template>
  <CommonContainer class="relative">
    <div class="relative z-10 mb-24 mt-[-50px] grid grid-cols-1 md:mt-[-80px] lg:mt-[-120px] lg:grid-cols-3 xl:mb-12">
      <div
        v-for="(tile, index) in tiles"
        :key="index"
        class="flex flex-col items-center p-8 text-center md:col-span-1 lg:p-12"
        :class="index % 2 === 0 ? 'bg-tertiary text-primary' : 'bg-primary text-white'"
      >
        <h2 class="mb-5 text-2xl md:text-3xl lg:text-5xl">
          {{ tile.heading }}
        </h2>
        <p class="mb-10 grow text-base lg:mb-14 lg:text-lg">{{ tile.copy }}</p>
        <CommonButtonNuxtLink
          class="mx-auto"
          :btn-style="index % 2 === 0 ? 'white' : 'tertiary'"
          btn-hover-style="secondary"
          :to="tile.buttonLink"
        >
          {{ tile.buttonText }}
        </CommonButtonNuxtLink>
      </div>
    </div>
    <CommonCommentTag class="grid-box" tag-class="grid-box" arrow="curve"> Volunteer today! </CommonCommentTag>
  </CommonContainer>
</template>
