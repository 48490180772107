<script setup lang="ts">
import dayjs from "dayjs";

// requesting branch info

// We need to randomise the branches fetched, but using a seeded random function that is based on the current date

// generate a random number between 0 and 1, then get the index using random number in the array of branch ids
function splitmix32(a) {
  return function () {
    a |= 0;
    a = (a + 0x9e3779b9) | 0;
    let t = a ^ (a >>> 16);
    t = Math.imul(t, 0x21f0aaad);
    t = t ^ (t >>> 15);
    t = Math.imul(t, 0x735a2d97);
    return ((t = t ^ (t >>> 15)) >>> 0) / 4294967296;
  };
}

// fetch all branch ids
const { data: branchIDs } = await useAsyncData(
  () => {
    return useDirectus(
      readItems("branches", {
        fields: ["id", "status"],
        filter: { status: { _eq: "published" } },
      })
    );
  },
  {
    default: () => [],
    transform: (branchIDs) => {
      const seed = dayjs().format("YYYYMMDD");
      const seededRandomNumber = splitmix32(seed)();
      const randomIndex = Math.floor(seededRandomNumber * branchIDs.length);

      const selectedBranchIDs = [];

      for (let i = 0; i < 3; i++) {
        selectedBranchIDs.push(branchIDs[(randomIndex + i) % branchIDs.length].id);
      }
      return selectedBranchIDs;
    },
  }
);

const { data: branches } = await useAsyncData(() =>
  useDirectus(
    readItems("branches", {
      fields: ["*", "count(branch_members)"],
      filter: { id: { _in: branchIDs.value } },
      limit: 3,
    })
  )
);
</script>
<template>
  <div class="relative bg-gray py-[--y-margin]">
    <CommonContainer class="relative">
      <CommonCommentTag class="branches" tag-class="branches"> Explore our groups! </CommonCommentTag>
      <div class="mb-8">
        <p class="mb-2 text-center font-script text-base font-bold text-secondary lg:text-left lg:text-3xl">Discover and Join</p>
        <h2 class="mb-7 text-center text-4xl font-semibold text-primary lg:text-left lg:text-5xl">Wildcare Groups</h2>
        <div class="flex flex-wrap items-center gap-5 lg:flex-row">
          <p class="mr-auto max-w-[750px] text-center text-xl text-primary lg:text-left">
            Browse the groups below and sign up to their email list by clicking 'Join this Group'. Join as many groups as you like, it's a
            great way to find out about opportunities, impact and stories.
          </p>
          <div class="mx-auto flex flex-wrap gap-5 lg:mx-0">
            <CommonButtonNuxtLink class="w-full md:w-fit" btn-style="secondary" btn-hover-style="primary" :to="{ path: '/groups' }">
              Explore More Groups
            </CommonButtonNuxtLink>
          </div>
        </div>
      </div>
      <div v-if="branches" class="space-y-7 py-16">
        <BranchPreview v-for="branch in branches" :key="branch.id" :branch="branch" />
      </div>
      <div class="flex justify-center">
        <CommonButtonNuxtLink class="w-full md:w-fit" btn-style="secondary" btn-hover-style="primary" :to="{ path: '/groups' }">
          Explore More Groups
        </CommonButtonNuxtLink>
      </div>
    </CommonContainer>
  </div>
</template>
